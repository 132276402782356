import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {FormControl, Grid, InputLabel, MenuItem, Select, TextField, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CreateCampaign, Loading } from "../../../../../../redux/action";
import { PublicService, LocalService, AddInfluencer, ApplyCampaignService } from '../../../../../../core/services';
import { FormValidation } from '../../../../../../core/utility';
import './add-influencer.scss';
import { MenuPropsSelect } from '../../../../../../core/utility/style';
import { createPortal } from 'react-dom';
import GenericSwipeableDrawer from '../../../../../../components/generic-swipe-drawer/generic-swiper-drawer';
import InvitedInfluencerList from './invited-influencer-list';
import ConfirmPopup from '../../../../../../components/confirm-popup/confirm-popup';
import { RolesService } from '../../../../../../core/services/roles.service';
import { useNavigate } from 'react-router-dom';

const publicService: PublicService = new PublicService();
const localService: LocalService = new LocalService();
const formValidation: FormValidation = new FormValidation();
const addInfluencer: AddInfluencer = new AddInfluencer();
const applyCampaignFlowService : ApplyCampaignService = new ApplyCampaignService();
const rolesService:RolesService =new RolesService();

interface Props {
    UserReducer: any;
    loading: (data: any) => void;
    selectedCampaign: any;
};



const initialDeliverables = {
    contactType: 'Influencer',
    talentPartner: '',
    influencerName: '',
    influencerUsername: '',
    phone: '',
    email: '',
    deliverables: {},
    influencerData: {},
    baseUrl: ''
};


const CampaingInfluencerCustom: React.FC<Props> = (props: Props) => {
    //campaign Deliverables
    const [deliverables, setDeliverables] = useState<any>(initialDeliverables);
    //Influencer Data with deliverables
    const [influencerData, setInfluencerData] = useState<any>([]);
    //platform Type
    const [platformType] = useState(props.selectedCampaign.campaign_platform);
    //influencer Profile Data
    const [verifyInfluencer, setVerifyInfluencer] = useState<any>(null);
    //button loading
    const [loading, setLoading] = useState(false);
    //field Errors 
    const [fieldErrors, setFieldErrors] = useState({
        influencerName: { status: true, message: '' },
        influencerUsername: { status: true, message: '' },
        phone: { status: true, message: '' },
        email: { status: true, message: '' },
        verify: { status: true, message: '' }
    });
    const navigate = useNavigate();

    const [sendWhatsAppInviteActive, setSendWhatsAppInviteActive] = useState(false)
    const [checkedInfluencers, setCheckedInfluencers] = useState<number[]>([]);
    const [campaignDeliverables, setCampaignDeliverables] = useState([]);
    const [invitedInfluencer,setInvitedInfluencer] =useState([]);
    const [accountExistsError, setAccountExistsError] = useState({
        message: "",
        status: false
    })
    const [verifyError, setAccountVerifyError] = useState({
        message: "",
        status: false
    })
    const [deliverableError, setDeliverableError] = useState({
        message: "",
        status: false
    })
    const [editInfluencer, setEditInfluencer] = useState(false)


    const checkboxcheckedStatus = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        if (event.target.checked) {
            setCheckedInfluencers((prev) => [...prev, index]);
        } else {
            setCheckedInfluencers((prev) => prev.filter((i) => i !== index));
        }
    }
    useEffect(() => {
        // CHECK IF USER ALLOWED  ACCESS 
        checkAccessFunction(props);
      }, [props]);
    
      const checkAccessFunction = async (props: Props) => {
        try {
          const res = await rolesService.checkAccess({ access_roles: ["wa_flow"], user_role_id: props?.UserReducer?.user_role_id })
          if (res.data.rows.length > 0) {
            getInvitedInfluencers();
            getInfluencerCampaignDetail();
              }
          else {
            localService.toastify("You are not allowed to access", "error", 1000);
    
            navigate(`/brand/dashboard`)
          }
        }
        catch (error) {
          console.error('Error fetching getAdminDashData:', error);
    
        }
      }
    
    useEffect(() => {
        setSendWhatsAppInviteActive(checkedInfluencers.length > 0);
    }, [checkedInfluencers]);


    async function getInvitedInfluencers(){
        const response = await applyCampaignFlowService.getInvitedInfluencer({campaignID:props?.selectedCampaign?.campaign_id});
        setInvitedInfluencer(response.data.rows)
        }
    async function getInfluencerCampaignDetail(){
        props.loading(true);
        publicService.influencerCampaignDetail(null, props?.selectedCampaign?.campaign_id, '').then(
            (res: any) => {
                setCampaignDeliverables(res.data.rows.deliverables);
            }
        )
            .catch(error => console.log(`Campaign Detail Brand Side function failed : ${error.toString()}`))
            .finally(() => props.loading(false));

    }
    function getDeliverables(followersCount:any) {
        let influencerType = "";
        if (followersCount >= 1000 && followersCount < 10000) {
            influencerType = "Nano (1K - 10K)";
        } else if (followersCount >= 10001 && followersCount < 50000) {
            influencerType = "Micro (10K - 50K)";
        } else if (followersCount >= 50001 && followersCount < 100000) {
            influencerType = "Micro Plus (50K - 100K)";
        } else if (followersCount >= 100001 && followersCount <= 500000) {
            influencerType = "Macro (100K - 500K)";
        } 
        else if (followersCount >= 500001 && followersCount <= 1000000) {
          influencerType = "Macro Plus (500K - 1M)";
        } 
        else if (followersCount >= 1000001 && followersCount <= 5000000) {
        influencerType = "Mega (1M - 5M)";
        } 
        else if (followersCount >= 5000001) {
          influencerType = "Mega Plus (5M+)";
          
          } 
        else {
            return 0;
        }
        return influencerType;
      }
    async function addInfluencerData() {
        const validateInfluencerName = formValidation.GetNameValidate(deliverables.influencerName);
        const validateInfluencerUsername = formValidation.GetNameValidate(deliverables.influencerUsername);
        const validatePhone = formValidation.GetMobileControlIsValid(deliverables.phone);
        const validateEmail = formValidation.GetEmailControlIsValid(deliverables.email);
        setFieldErrors(
            {
                ...fieldErrors,
                influencerName: validateInfluencerName,
                influencerUsername: validateInfluencerUsername,
                phone: validatePhone,
                email: validateEmail
            }
        );
        if (verifyInfluencer == null && validateInfluencerUsername.status) {
            setAccountVerifyError({
                status: true,
                message: "Please Verify handle name"

            })

        }
        else{
           const checkDeliverables = verifyInfluencer?.followers_count ?  getDeliverables(verifyInfluencer?.followers_count) : getDeliverables(verifyInfluencer?.subscriber_count)
            const deliverableArray = campaignDeliverables?.filter((item:any) => {
                return item.influencer_type === checkDeliverables;
            })

            if(deliverableArray.length == 0){
             setDeliverableError({ 
                message: "Please select valid influencer according to the deliverables defined",
                status: true
            })
            }
            else{
                setDeliverableError({ 
                    message: "",
                    status: false
                })
            }
            if ( validateInfluencerName.status && validateInfluencerUsername.status && validatePhone.status && validateEmail.status && verifyInfluencer !== null && deliverableArray.length != 0) {
                const response = await publicService.checkIfInfluencer({
                    mobile: Number(deliverables.phone),
                    email: deliverables.email,
                });
                if (response.data.status.message.length == 0) {
                    setInfluencerData((oldData: any) => [...oldData, { ...deliverables, influencerData: verifyInfluencer, baseUrl: window.location.origin },]);
                    setVerifyInfluencer(null);
                    setDrawerOpen(false)
                    setDeliverables({
                        contactType: "",
                        influencerName: "",
                        influencerUsername: '',
                        phone: "",
                        email: "",
                        talentPartner: ""
                    })
                    setAccountExistsError({
                        message: "",
                        status: false
                    })
                    setAccountVerifyError({
                        message: "",
                        status: false
                    })
                    setDeliverableError({ 
                        message: "",
                        status: false
                    })
    
            
                }
                else {
                    localService.toastify(response.data.status)
                    setAccountExistsError({
                        status: true,
                        message: response.data.status.message
        
                    })
                }
    
    
            }
        }




    };

    function removeInfluencerData(index: number) {
        let temp = [...influencerData];
        temp.splice(index, 1);
        setInfluencerData(temp);
    };



    // const editInfluencerData = (index: number) => {
    //     const influencer = influencerData[index];
    //     // setSelectedInfluencer(influencer);
    //     setDeliverables({
    //         contactType: influencer.contactType,
    //         influencerName: influencer.influencerName,
    //         influencerUsername: influencer.influencerUsername,
    //         talentPartner: influencer.talentPartner,
    //         phone: influencer.phone,
    //         email: influencer.email,
    //         // Add other fields as necessary
    //     });
    //     setDrawerOpen(true);
    // };

    const handleInstagramProfile = async () => {
        setLoading(true);
        try {

            if (deliverables.influencerUsername) {
                const response = await publicService.instagramVerify(deliverables.influencerUsername);
                if (response?.status === 1) {
                    localService.toastify(response.info, 'success', 1000);
                    setVerifyInfluencer({biography:response.data.biography,
                    er:response.data.er,
                    followers_count:response.data.followers_count,
                    follows: response.data.follows,
                    insta_username:response.data.insta_username,
                    instagram_id:response.data.instagram_id,
                    logging_page_id:response.data.logging_page_id,
                    median_comments:response.data.median_comments,
                    median_likes:response.data.median_likes,
                    name:response.data.name,
                    profile_picture_url:response.data.profile_picture_url,
                    total_comments:response.data.total_comments,
                    total_likes:response.data.total_likes,
                    total_media:response.data.total_media,
                    total_media_public_profile:response.data.total_media_public_profile,
                    total_video_counts_public_profile:response.data.total_video_counts_public_profile

                    });
                    //verify Status Update;
                    setFieldErrors(res => {
                        res.verify.status = true;
                        return { ...res }
                    })
                } else {
                    localService.toastify("Unable to verify handlename", 'error', 1000);
                }
            }

        } catch (error) {
            throw new Error(`handleInstagramProfile Error :: ${error}`)
        } finally {
            setLoading(false);
        }
    }

    const handleYoutubeProfile = async () => {
        setLoading(true);
        try {

            if (deliverables.influencerUsername) {
                const response = await publicService.youtubeVerify(deliverables.influencerUsername);
                if (response?.status === 1) {
                    localService.toastify(response.info, 'success', 1000);
                    setVerifyInfluencer(response.data);
                    //verify Status Update;
                    setFieldErrors(res => {
                        res.verify.status = true;
                        return { ...res }
                    })
                } else {
                    localService.toastify("Unable to verify channel id", 'error', 1000);
                }
            }

        } catch (error) {
            throw new Error(`handleYoutubeProfile Error :: ${error}`)
        } finally {
            setLoading(false);
        }
    }
    /*
        Handle handleInstagramProfile AND handleYoutubeProfile
    */
    const handleInfluencerType = () => {
        try {
            if ((platformType + '').toLocaleLowerCase() === 'instagram') {
                handleInstagramProfile();
            } else if ((platformType + '').toLocaleLowerCase() === 'youtube') {
                handleYoutubeProfile();
            }
        } catch (error) {
            throw new Error(`handleInfluencerType Error ::  ${error}`)
        }
    };

    /*
    Send Invitation
    */


    async function sendInvitation() {
        try {
            setLoading(true);
            const response = await addInfluencer.addInfluencer({ campaignId: props.selectedCampaign.campaign_id, influencerData });
            let status = response.data.status.status ? 'success' : 'error';
            localService.toastify(response.data.status.message, status, 1000);
            if (status === 'success') {
                setInfluencerData(initialDeliverables);
                getInvitedInfluencers();

            }

        } catch (err) {
            throw new Error(`sendInvitation function error :: ${JSON.stringify(err)}`)
        } finally {
            setLoading(false);
        }
    }

    const [drawerOpen, setDrawerOpen] = useState(false);
    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDeliverables({
            contactType: "",
            influencerName: "",
            influencerUsername: '',
            phone: "",
            email: "",
            talentPartner: ""
        })
        setAccountExistsError({
            message: "",
            status: false
        })
        setAccountVerifyError({
            message: "",
            status: false
        })
        setDeliverableError({ 
            message: "",
            status: false
        })

        setFieldErrors({       
            influencerName: { status: true, message: '' },
            influencerUsername: { status: true, message: '' },
            phone: { status: true, message: '' },
            email: { status: true, message: '' },
            verify: { status: true, message: '' }
         })
         setVerifyInfluencer(null);
         setDrawerOpen(false);
        setEditInfluencer(false)
    

    };

    const [openSendWhatsAppInvite, setOpenSendWhatsAppInvite] = useState(false);
    const handleSendWhatsAppInvite = () => {
        if(sendWhatsAppInviteActive){
            setOpenSendWhatsAppInvite(true);
            document
                .getElementsByTagName('body')[0]
                .classList.add('custom-drawer-active');
    
        }
    }

    const handleSendWhatsAppInviteClose = () => {
        setOpenSendWhatsAppInvite(false);
        document
            .getElementsByTagName('body')[0]
            .classList.remove('custom-drawer-active');
    };

    const [selectedInfluencerIndex, setSelectedInfluencerIndex] = useState<number | null>(null);

    const updateInfluencerData = (index: number, updatedData: any) => {
        setInfluencerData((prev:any) =>
            prev.map((influencer:any, i:any) => i === index ? { ...influencer, ...updatedData } : influencer)
        );
    };
   
    const editInfluencerData = (index: number) => {
        setSelectedInfluencerIndex(index);
        setEditInfluencer(true)
        const influencer = influencerData[index];
        setDeliverables({
            contactType: influencer.contactType,
            influencerName: influencer.influencerName,
            influencerUsername: influencer.influencerUsername,
            talentPartner: influencer.talentPartner,
            phone: influencer.phone,
            email: influencer.email,
            influencerData:influencer.influencerData
            // Add other fields as necessary
        });
        setVerifyInfluencer(influencer.influencerData)
        setDrawerOpen(true);
    };
    const handleSaveButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        if (selectedInfluencerIndex !== null) {
            const validateInfluencerName = formValidation.GetNameValidate(deliverables.influencerName);
            const validateInfluencerUsername = formValidation.GetNameValidate(deliverables.influencerUsername);
            const validatePhone = formValidation.GetMobileControlIsValid(deliverables.phone);
            const validateEmail = formValidation.GetEmailControlIsValid(deliverables.email);
            setFieldErrors(
                {
                    ...fieldErrors,
                    influencerName: validateInfluencerName,
                    influencerUsername: validateInfluencerUsername,
                    phone: validatePhone,
                    email: validateEmail
                }
            );
            if (verifyInfluencer == null && validateInfluencerUsername.status) {
                setAccountVerifyError({
                    status: true,
                    message: "Please Verify handle name"
    
                })
    
            }
            else{
               const checkDeliverables = verifyInfluencer?.followers_count ?  getDeliverables(verifyInfluencer?.followers_count) : getDeliverables(verifyInfluencer?.subscriber_count)
                const deliverableArray = campaignDeliverables?.filter((item:any) => {
                    return item.influencer_type === checkDeliverables;
                })
    
                if(deliverableArray.length == 0){
                 setDeliverableError({ 
                    message: "Please select valid influencer according to the deliverables defined",
                    status: true
                })
                }
                else{
                    setDeliverableError({ 
                        message: "",
                        status: false
                    })
                }
                if ( validateInfluencerName.status && validateInfluencerUsername.status && validatePhone.status && validateEmail.status && verifyInfluencer !== null && deliverableArray.length != 0) {
                    const response = await publicService.checkIfInfluencer({
                        mobile: Number(deliverables.phone),
                        email: deliverables.email,
                    });
                    if (response.data.status.message.length == 0) {
                                  const updatedData = {
                    contactType: deliverables.contactType,
                    influencerName: deliverables.influencerName,
                    influencerUsername: deliverables.influencerUsername,
                    talentPartner: deliverables.talentPartner,
                    phone: deliverables.phone,
                    email: deliverables.email,
                    influencerData:deliverables.influencerData
                    // Add other fields as necessary
                };
    
                updateInfluencerData(selectedInfluencerIndex, updatedData);
    
                        setVerifyInfluencer(null);
                        setDrawerOpen(false)
                        setDeliverables({
                            contactType: "",
                            influencerName: "",
                            influencerUsername: '',
                            phone: "",
                            email: "",
                            talentPartner: ""
                        })
                        setAccountExistsError({
                            message: "",
                            status: false
                        })
                        setAccountVerifyError({
                            message: "",
                            status: false
                        })
                        setDeliverableError({ 
                            message: "",
                            status: false
                        })
        
                
                    }
                    else {
                        localService.toastify(response.data.status)
                        setAccountExistsError({
                            status: true,
                            message: response.data.status.message
            
                        })
                    }
        
        
                }
            }
    
        }
    };
    const isSmallScreen = useMediaQuery((theme: any) =>
        theme.breakpoints.down('sm')
    );

    const drawerAnchor = isSmallScreen ? 'bottom' : 'right';


    const drawerContent = (
        <>
            <Grid
                container
                spacing={2}
                rowSpacing={2}
            >
                <Grid item xs={12} sm={12} lg={12}>
                    <FormControl fullWidth size='small' >
                        <InputLabel id="demo-simple-select-label">Contact Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={deliverables.contactType}
                            label="Contact Type"
                            onChange={(e) => setDeliverables({ ...deliverables, contactType: e.target.value })}
                            MenuProps={MenuPropsSelect}
                        >
                            <MenuItem value='Influencer'>Influencer</MenuItem>
                            {/* <MenuItem value='Talent Manager'>Talent Manager</MenuItem> */}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                    <TextField size="small"
                        onChange={(e) => setDeliverables({ ...deliverables, influencerName: e.target.value })}

                        value={deliverables.influencerName}
                        error={!fieldErrors.influencerName.status}
                        className='w-100'
                        id="id_Influencer"
                        name="influencer"
                        label="Influencer Name"
                        variant="outlined" />
                </Grid>

                <Grid item xs={12} sm={12} lg={12}>
                    <div className='d-flex gap-2'>
                        <div className='col sec-add-influencer-verify'>
                            <TextField size="small"
                                onChange={(e) => setDeliverables({ ...deliverables, influencerUsername: e.target.value })}
                                value={deliverables.influencerUsername}
                                error={!fieldErrors.influencerUsername.status}
                                className='w-100 add-influencer-verify-input'
                                id="id_Influencer"
                                name="influencer"
                                label="Influencer username"
                                disabled={deliverableError.status || (verifyInfluencer && !editInfluencer)  ? true : false}
                                variant="outlined" />
                            <LoadingButton
                                loading={loading}
                                className="btn btn-outline-primary btn-mui"
                                loadingPosition="start"
                                variant="outlined"
                                onClick={handleInfluencerType}
                                disabled={(verifyInfluencer && !editInfluencer)|| deliverableError.status ? true : false}
                            >
                                Verify
                            </LoadingButton>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} lg={12} className='pt-0'>
                    {verifyError && <span className='error'>{verifyError?.message}</span>}
                </Grid>

                {deliverables.contactType === 'Talent Partner / Manager' &&
                    <Grid item xs={12} sm={12} lg={12}>
                        <TextField size="small"
                            onChange={(e) => setDeliverables({ ...deliverables, talentPartner: e.target.value })}
                            value={deliverables.talentPartner}
                            className='w-100'
                            id="id_Talent"
                            name="talent"
                            label="Talent partner / Manager Name"
                            variant="outlined" />

                    </Grid>}
                <Grid item xs={12} sm={12} lg={12}>
                    <TextField size="small"
                        onChange={(e) => setDeliverables({ ...deliverables, phone: e.target.value })}
                        value={deliverables.phone}
                        error={!fieldErrors.phone.status}
                        className='w-100'
                        id="id_Phone"
                        name="phone"
                        label="Phone *"
                        variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                    <TextField size="small"
                        onChange={(e) => setDeliverables({ ...deliverables, email: e.target.value })}
                        value={deliverables.email}
                        error={!fieldErrors.email.status}
                        className='w-100'
                        id="id_Email"
                        name="email"
                        label="Email"
                        variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={12} lg={12}>
                    {accountExistsError && <span className='error'>{accountExistsError?.message}</span>}
                    {deliverableError && <span className='error'>{deliverableError?.message}</span>}

                </Grid>
                <Grid item xs={12} sm={12} lg={12} className='text-right' style={{ textAlign: 'right' }}>
                    <button className='btn btn-primary' onClick={editInfluencer ? handleSaveButtonClick : addInfluencerData}>
                        {editInfluencer ? "Update Influencer" : "Add Influencer"}
                    </button>
                </Grid>
            </Grid>

            {verifyInfluencer && <>
                {(platformType + '').toLocaleLowerCase() === 'instagram' && <div className="row">
                    <div className="col">
                        <ul>
                            <li>Name: <span><strong>{verifyInfluencer.name}</strong></span></li>
                            <li>Username: <span><strong>{verifyInfluencer.insta_username}</strong></span></li>
                            <li>Followers <span><strong>{verifyInfluencer.followers_count}</strong></span></li>
                        </ul>
                    </div>
                </div>}
                {(platformType + '').toLocaleLowerCase() === 'youtube' && <div className="row">
                    <div className="col">
                        <ul>
                            <li>Channel Name: <span><strong>{verifyInfluencer.channel_title}</strong></span></li>
                            <li>Channel Id: <span><strong>{verifyInfluencer.channel_id}</strong></span></li>
                            <li>Custom Url: <span><strong>{verifyInfluencer.custom_url}</strong></span></li>
                            <li>Country: <span><strong>{verifyInfluencer.country}</strong></span></li>
                            <li>Subscribers: <span><strong>{verifyInfluencer.subscriber_count}</strong></span></li>
                            <li>Videos: <span><strong>{verifyInfluencer.video_count}</strong></span></li>
                        </ul>
                    </div>
                </div>}
            </>}
        </>
    );
    return (
        <div className="container-fluid custom-infl-main-container">
            <div className="row">
                <div className="col">
                    <div className="row mb-3">
                        <div className="col mt-3">
                            <span><strong>Invitees</strong></span>
                        </div>
                        <div className='col-auto d-flex gap-2'>
                            <button onClick={handleSendWhatsAppInvite} className={sendWhatsAppInviteActive ? "btn btn-mui btn-outline-primary" : 'btn btn-disabled btn-mui'}>
                                Send WhatsApp Invite
                            </button>
                            <button className='btn btn-primary' onClick={handleDrawerOpen}>
                                Add Influencer
                            </button>
                        </div>
                    </div>
                   { 
                    (influencerData.length > 0 ||invitedInfluencer.length > 0) &&
                        <div className='selected-influencer-list-combined'>
                            <div className='sec-invited-influencer-table'>
                                <span className='sec-invited-influencer-table-checkbox'></span>
                                <span className='sec-invited-influencer-table-info'>Influencer Handle</span>
                                <span className='sec-invited-influencer-table-contact-info'>Phone/E-mail</span>
                                <span className='sec-invited-influencer-table-deliverable'>Deliverables</span>
                                <span className='sec-invited-influencer-table-actions'>Action</span>
                            </div>
                            {/* //table Section */}
                            {influencerData.length > 0 &&
                                <InvitedInfluencerList checkedInfluencers={checkedInfluencers} removeInfluencerData={removeInfluencerData} editInfluencerData={editInfluencerData} checkboxcheckedStatus={checkboxcheckedStatus} influencerData={influencerData} campaignDeliverables={campaignDeliverables}
                                updateInfluencerData={updateInfluencerData} />
                            }
                            {invitedInfluencer.length > 0 &&
                                <InvitedInfluencerList checkedInfluencers={checkedInfluencers}  influencerData={invitedInfluencer} campaignDeliverables={campaignDeliverables} />
                            }
                        </div>
                    }
                </div>
            </div>
            {createPortal(
                <GenericSwipeableDrawer
                    className='guide-to-connect'
                    drawerAnchor={drawerAnchor}
                    open={drawerOpen}
                    onClose={handleDrawerClose}
                    onOpen={handleDrawerOpen}
                    title={editInfluencer ? "Update Influencer" : "Add Influencer"}
                    content={drawerContent}
                />, document.body)}

            {createPortal(
                <ConfirmPopup extraClass="confirm-send-whatsapp" confirmPopupInnerText="I confirm that I fully accept the responsibility for the accuracy and integrity of the given details.
                                    I understand that any consequences or liabilities resulting from the information provided are solely my own. Furthermore,
                                    I affirm that the provided information will not be utilized for spamming purposes." openPopup={openSendWhatsAppInvite} confirmClosePopup={handleSendWhatsAppInviteClose} confirmClickAction={sendInvitation} drawerTitle="" />, document.body)}
        </div>

    )
};



const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
        selectedCampaign: state.InfSelectCamp.campaign
    }
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        CreateCampaignReducer: (data: any) => dispatch(CreateCampaign(data)),
        loading: (data: any) => dispatch(Loading(data))
    }
}

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(CampaingInfluencerCustom);

export { connectToRedux as AddInfluencers };