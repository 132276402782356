import { Skeleton } from '@mui/material'
import React, { useState } from 'react'
import { DefaultUserProfile } from '../../../config/config';
import { Link } from 'react-router-dom';
import InstagramIcon1 from '../../../components/icons/instagram-icon1';
import MaterialSymbol from '../../../components/icons/material-symbol';

type Props = {
  influencerData: any
  loading: boolean;
  email?: any;
  phone?: any;
  location?: any;
  category?: any;
}
const InfluencerProfileLeft = ({ influencerData, email, phone, loading, location, category }: Props) => {
  const [showMoreText, setShowMoreText] = useState(false)
  const handleMoreText = () => {
    setShowMoreText((prev: boolean) => !prev)
  }
  const dob = influencerData?.detail?.[0]?.dob;
  const shouldRenderAge = dob && dob !== '1970-01-01';
  return (
    <>
      {/* // FOR DESKTOP VIEW */}
      <div className='influencer-profile__left-inner d-none d-lg-flex'>
        <div className='influencer-profile__image'>
          {loading ? <Skeleton width={120} height={120} variant='circular' /> : <img
            src={
              influencerData &&
              influencerData.profile_detail &&
              influencerData.profile_detail.length > 0
              && ((influencerData.profile_detail[0].gcp_profile_image_url ? influencerData.profile_detail[0].gcp_profile_image_url : influencerData.profile_detail[0].profile_image_url)
                ||
                DefaultUserProfile?.influencer_unknown_default)
            }

            alt="Influencer Profile"
          />
          }
          <div className='influencer-profile__image--curve'></div>
          <div className='influencer-profile__image--curve-left'></div>
        </div>
        <div className='influencer-user__info'>
          {loading ? (
            <Skeleton height={30} />
          ) : (
            <h3>{influencerData?.profile_detail?.[0]?.insta_name}</h3>
          )}
          <span className='fs-14'>
            {influencerData?.agegender_detail?.[0]?.age &&
              influencerData.agegender_detail[0].age !== '1970-01-01' && (
                <>
                  {influencerData.agegender_detail[0].age}
                  {influencerData.agegender_detail[0].gender !== 'Other' && influencerData.agegender_detail[0].age.trim() !== '' && ', '}
                </>
              )}

            {loading ? (
              <Skeleton />
            ) : (
              influencerData?.agegender_detail?.[0]?.gender !== 'Other' &&
              influencerData?.agegender_detail?.[0]?.gender
            )}
          </span>
        </div>
        
        <div className='influencer-user__location'>
          {(email && email.length > 0) && <p className='d-flex align-items-center'><MaterialSymbol iconName='mail' fontweight='200' color='#929292' iconFilled /> <span className='ms-2'>{email}</span></p>}
          {(phone != 0 && phone) && 
            <p className='d-flex align-items-center'>
              <MaterialSymbol iconName='call' fontweight='200' color='#929292' iconFilled />
              <span className='ms-2'>{phone}</span>
            </p>
          }
          {(location && location.length > 0) && <p className='d-flex align-items-center'><MaterialSymbol iconName='location_on' fontweight='200' color='#929292' iconFilled /><span className='ms-1'>{location}</span></p>}
          {(category[0] || category[1] || category[2] || category[3]) &&
            <div className='influencer-user__category'>
              {category[0] && <span>{category[0]}</span>}
              {category[1] && <span>{category[1]}</span>}
              {category[2] && <span>{category[2]}</span>}
              {category[3] && <span>{category[3]}</span>}
            </div>
          }
        </div>
        <div className={`influencer-user__intro ${showMoreText ? "more-text" : ""}`}>
          <p className="mb-0">{loading ? <Skeleton /> : <>{influencerData?.profile_detail?.[0]?.biography}</>}</p>
          {influencerData?.profile_detail?.[0]?.biography && influencerData?.profile_detail?.[0]?.biography.length > 85 && <button onClick={handleMoreText} className='btn btn-text-link'>{showMoreText ? "less" : "more"}</button>}
        </div>
      </div>

      {/* // FOR MOBILE VIEW */}
      <div className='influencer-profile__left-inner influencer-profile__left-inner-mobile d-lg-none'>
        <div className='influencer-user-profile'>
          <div className='influencer-profile__image influencer-user-profile-left'>
            {loading ? <Skeleton width={120} height={120} variant='circular' /> : <img
              src={
                influencerData &&
                influencerData.profile_detail &&
                influencerData.profile_detail.length > 0
                && ((influencerData.profile_detail[0].gcp_profile_image_url ? influencerData.profile_detail[0].gcp_profile_image_url : influencerData.profile_detail[0].profile_image_url)
                  ||
                  DefaultUserProfile?.influencer_unknown_default)
              }

              alt="Influencer Profile"
            />
            }
          </div>
          <div className='influencer-user-profile-right'>
            <div className='d-flex d-lg-none'>
              <InstagramIcon1 width="20px" height="20px" />
              <Link
                className="influencer-profile__social-chan"
                to={`https://www.instagram.com/${influencerData && influencerData?.profile_detail?.[0]?.username}`}
                target="_blank"
              >
                <span className="influ-prof-info-social-username d-flex justify-content-center align-items-baseline">
                  {loading ? <Skeleton height={30} /> : <>{influencerData?.profile_detail?.[0]?.username}</>}
                </span>
              </Link>
            </div>
            <div className='influencer-user__info'>
              {loading ? <Skeleton height={30} /> : <h3>{influencerData && influencerData?.profile_detail?.[0]?.insta_name}</h3>}
              <span className='fs-14'>
                {influencerData?.agegender_detail?.[0]?.age ? influencerData?.agegender_detail?.[0]?.age : "NA"},{" "}
                {loading ? <Skeleton /> : <>{influencerData?.agegender_detail?.[0]?.gender}</>}
              </span>
            </div>
          </div>
        </div>
        <div className='influencer-user__intro'>
          <p className="mb-0">{loading ? <Skeleton /> : <>{influencerData?.profile_detail?.[0]?.biography}</>}</p>
        </div>
      </div>
    </>
  )
}

export default InfluencerProfileLeft
