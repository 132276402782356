import React, { useState } from 'react'
import MaterialSymbol from '../../../../../../components/icons/material-symbol';
import { DeliverableItemKeys } from '../proposals/proposal-components';

type Props = {
  influencerData:any;
  removeInfluencerData?: (index: number) => void;
  checkboxcheckedStatus?:any
  campaignDeliverables?: any;
  editInfluencerData?: (index: number) => void;
  checkedInfluencers?:any;
  updateInfluencerData?:any;
}

const InvitedInfluencerList = ({ influencerData, removeInfluencerData, checkboxcheckedStatus, campaignDeliverables, editInfluencerData, checkedInfluencers, updateInfluencerData }: Props) => {
  const [enumdeliverableItems] = useState<Record<DeliverableItemKeys, string>>({
    ig_reel: 'Reel',
    ig_story: 'Story',
    ig_carousel_image: 'Carousel Image Post',
    yt_shorts: 'Short',
    available_for_exclusivity: 'Exclusivity',
    ig_static: 'Static',
    digital_rights: 'Digital Rights',
    yt_dedicated_video: 'Dedicated Video',
    yt_integrated_video: 'Integrated Video',
    ["Package Cost 1"]:'Package Cost'
  });
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    checkboxcheckedStatus(event, index);
  }
  function getDeliverables(followersCount:any) {
    let influencerType = "";
    if (followersCount >= 1000 && followersCount < 10000) {
        influencerType = "Nano (1K - 10K)";
    } else if (followersCount >= 10001 && followersCount < 50000) {
        influencerType = "Micro (10K - 50K)";
    } else if (followersCount >= 50001 && followersCount < 100000) {
        influencerType = "Micro Plus (50K - 100K)";
    } else if (followersCount >= 100001 && followersCount <= 500000) {
        influencerType = "Macro (100K - 500K)";
    } 
    else if (followersCount >= 500001 && followersCount <= 1000000) {
      influencerType = "Macro Plus (500K - 1M)";
    } 
    else if (followersCount >= 1000001 && followersCount <= 5000000) {
    influencerType = "Mega (1M - 5M)";
    } 
    else if (followersCount >= 5000001) {
      influencerType = "Mega Plus (5M+)";
      } 
    else {
        return "Followers count out of range for defined influencer types.";
    }

    const deliverables = campaignDeliverables?.filter((item:any) => {
      return item.influencer_type === influencerType;
  })

    return <>{deliverables[0]?.deliverables.map((deliverable:any)=>{
      return <li>{deliverable}</li>
    })}</>;
  }
  return (
    <section className='sec-invited-influencer-list'>
      
      {influencerData && influencerData.map((influencer:any, index: number)=>{
        return <div id={`invited-influencer-${influencer?.influencer_id}`} key={influencer?.influencer_id} className='invited-influencer-row'>
          {checkboxcheckedStatus && <input disabled={!influencerData.some((item: any) => item.influencer_id === influencer?.influencer_id)} className='invited-influencer-checkbox' id={`influencer-${influencer.influencer_id}`} type='checkbox' onChange={(event) => handleCheckboxChange(event, index)} />}   
          {influencer?.influencer_id && <span className='influencer-checkbox-disabled'></span>}
           <div className='invited-influencer-info'>
            <span className='invited-influencer-name'>{influencer?.influencerUsername}</span>
            <span className='invited-influencer-type'>{influencer?.influencer_type && influencer?.influencer_type == "Invited Influencer" ? "Influencer" : influencer?.contactType}</span>
          </div>
          <div className='invited-influencer-contact-info'>
            <span className='invited-influencer-phone'>{influencer?.phone}</span>
            <span className='invited-influencer-email'>{influencer?.email}</span>
          </div>
          <div className='invited-influencer-deliverable'>
            {checkboxcheckedStatus && 
            <ul>
            {influencer?.influencerData?.followers_count ? getDeliverables(influencer?.influencerData?.followers_count) : getDeliverables(influencer?.influencerData?.subscriber_count)}
            </ul>
            }
            { !checkboxcheckedStatus &&
             <ul>
              {influencer.ig_reel !==0 && <li> { influencer.ig_reel } {enumdeliverableItems["ig_reel"]} </li>}
              {influencer.ig_story !==0 && <li> { influencer.ig_story } {enumdeliverableItems["ig_story"]} </li>}
              {influencer.ig_carousel_image !==0 && <li> { influencer.ig_carousel_image } {enumdeliverableItems["ig_carousel_image"]} </li>}
              {influencer.yt_shorts !==0 && <li> { influencer.yt_shorts } {enumdeliverableItems["yt_shorts"]} </li>}
              {influencer.available_for_exclusivity !==0 && <li> { influencer.available_for_exclusivity } days {enumdeliverableItems["available_for_exclusivity"]} </li>}
              {influencer.ig_static  !==0 && <li> { influencer.ig_static } {enumdeliverableItems["ig_static"]} </li>}
              {influencer.digital_rights !==0 &&  <li> { influencer.digital_rights } days {enumdeliverableItems["digital_rights"]} </li>}
              {influencer.yt_dedicated_video !==0 && <li> { influencer.yt_dedicated_video } {enumdeliverableItems["yt_dedicated_video"]} </li>}
              {influencer.yt_integrated_video !==0 && <li> { influencer.yt_integrated_video } {enumdeliverableItems["yt_integrated_video"]} </li>}
             </ul>

            }

          </div>
          <div className='invited-influencer-actions'>
            <span className={`${(!influencer?.influencer_id) ? "invited-influencer-chat disabled" : ""}`}>
              <MaterialSymbol iconName='chat_bubble' fontweight='200' iconFilled={!influencer?.influencer_id} />
            </span>
            
            {editInfluencerData && <span className='invited-influencer-edit cursor-pointer' onClick={() => editInfluencerData(index)}>
              <MaterialSymbol iconName='edit' fontweight='200' />
            </span>}
            {removeInfluencerData && <span className='invited-influencer-delete cursor-pointer' onClick={() => removeInfluencerData(index)}><MaterialSymbol  iconName='delete' fontweight='200' /></span>}

          </div>
        </div>
      }) 

      }
    </section>
  )
}

export default InvitedInfluencerList