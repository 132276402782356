import React, { Fragment, useEffect, useState } from "react";
import { Grid, Skeleton } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import GirlIcon from '@mui/icons-material/Girl';
import BoyIcon from '@mui/icons-material/Boy';
import { PieChart, ColumnChart } from '../../../components/charts';
import { InfluencerProfileService } from '../../../core/services/influencer-profile.service';
import { ListService, LocalService } from '../../../core/services';
import { DefaultUserProfile } from '../../../config/config';
import { ResponseType } from "custom-type";
import { connect } from "react-redux";
import { Loading } from "../../../redux/action";
import { HandlesService } from "../../../core/services/handles.service";
import InstagramIcon from "../../../components/icons/instagram-icon";
import { CreateCampaign as CampaignService } from "../../../core/services";
import { FormValidation, Methods } from "../../../core/utility";
import { Link } from 'react-router-dom';
import InsightMediaDetailCard from './post-card';
import InsightMediaReelsCard from './reels-card';
import InsightMediaCarouselCard from './carousel-card';
import InsightMediaImageCard from './image-card';
import CityList from './city-list';
import { LineChartDropDown } from "../../../components/charts/line-chart";
import AddToCampaign from "../../../components/add-to-campaign/add-to-campaign";
import AddToList from "../../../pages/brand/discover/add-to-list";
import UpgradeBrandPopUp from "../../../components/popup/upgrade-brand-popup";
import InfluencerProfileLeft from "./influencer-profile-left";
import FollowerCard from "./follower-card";
import FollowerCardInline from "./follower-card-inline";
import FollowerSection from "./followers/follower-sec";
import FollowerSecInline from "./followers/follower-sec-inline";
import PostFrequency from "./followers/post-frequency";
import Audience from "./audience/audience";
import Engagement from "./engagement/engagement";
import InstagramIcon1 from "../../../components/icons/instagram-icon1";

const inflProfileService: InfluencerProfileService = new InfluencerProfileService()
const handlesService = new HandlesService();
const methods = new Methods();

interface Props {
  UserReducer: any;
  loading: (data: any) => void;
  getSubscriptionDetail:any;
}


const listService: ListService = new ListService()

const ProfileInsight: React.FC<Props> = (props: Props) => {
  const [influencerId] = useState(() => {
    const idString = new URLSearchParams(window.location.href).get('influencerId');
    return idString ? parseInt(idString, 10) : 0;
  });

  const [selectInflu, setSelectInflu] = useState<any>([]);
  const [influencerData, setInfluencerData] = useState<any>([]);
  const [genderSplitPieChartData, setGenderSplitPieChartData] = useState<any[]>([]);
  const [loading, setIsLoading] = useState(false)
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const [columnChartData, setColumnChartData] = useState<any[]>([]);
  const [genderArrayLabel, setGenderArrayLabel] = useState()
  const [postErComparisonData, setPostErComparisonData] = useState<any[]>([]);
  const [influencerList, setInfluencerList] = useState<any>(null);
  const [value, setValue] = useState("1");
  const [showPopAddToCampaign, setShowPopAddToCampaign] = useState(false);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const [showAddToListPopup, setShowAddToListPopup] = useState(false);
  const [openUpgradeBrandModal, setOpenUpgradeBrandModal] = useState<boolean>(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState('');
  const [categories, setCategories] = useState([]);
  // to get connected instagram account sm_id
  async function getConnectedAccountId(influencer_id: number | string) {
    try {
      const res: any = await handlesService.getConnectedAccounts(influencer_id);

      if (res?.data?.rows.length > 0) {
        const connectedAccountData = res.data.rows.filter(
          (filter: any) => filter.account_type === 'Primary'
        );

        if (connectedAccountData.length > 0) {
          return connectedAccountData[0].ig_id;
        }
      }

    } catch (err: any) {
      console.error('Error fetching connected accounts:', err);
      // Handle the error as needed...
    }

    // Return a default value or handle the case when no connected account is found
    return null;
  }

  useEffect(() => {
    const fetchData = async () => {
      let idToSet;
      try {
        if (urlParams.get("sm_id") == null) {
          idToSet = await getConnectedAccountId(props?.UserReducer?.influencer_id);
        } else {
          idToSet = urlParams.get("sm_id");
        }
      } catch (error) {
        console.error('Error setting Instagram ID:', error);
      }

      // Continue with other logic after setting Instagram ID
      getProfileInsight(idToSet);
    };

    fetchData();
  }, []);

   useEffect(() => {

   }, [props.getSubscriptionDetail])


  //3rd chart
  const getProfileInsight = async (instagramID: any) => {
    setIsLoading(true)
    try {
      const response = await inflProfileService.getInfluencerProfile({
        sm_id: instagramID,
        user_id: props.UserReducer.user_id,
        account_id: props.UserReducer.account_id,
        brand_id: props.UserReducer.brand_id,
        influencer_id: props.UserReducer.influencer_id
      });
      if (response.status === 1) {
        setInfluencerData(response?.data?.rows)
        setIsLoading(false);
        setSelectInflu(response?.data?.rows?.profile_detail)
        setPhone(response?.data?.rows?.email_phone[0]?.primary_phone)
        setEmail(response?.data?.rows?.email_phone[0]?.primary_email)
        const data = response.data.rows.location_category[0];
        const locationString = `${data.state}, ${data.country}`;
        setLocation(locationString);

        // Extract categories and filter out null values
        const categoryList:any = [
          data.category1,
          data.category2,
          data.category3,
          data.category4
        ].filter(category => category !== null);
        setCategories(categoryList);
        const ageGenderData = response?.data?.rows?.age_gender;
        if (Array.isArray(ageGenderData)) {
          // let fGenderArray: any = ageGenderData?.filter((item) => item.age_gender_range.includes("F."));
          // let mGenderArray: any = ageGenderData?.filter((item) => item.age_gender_range.includes("M."));
          let mGenderArray = ageGenderData
            .filter((item) => (item.age_gender_range.startsWith("M_") || item.age_gender_range.startsWith("M.")))
            .map((item) => item.value);

          let fGenderArray = ageGenderData
            .filter((item) => (item.age_gender_range.startsWith("F_") || item.age_gender_range.startsWith("F.")))
            .map((item) => item.value);

          let genderArrayLabelSet = new Set(ageGenderData.map(item => item.age_gender_range.replace(/[FMU_, F.M.U.]/g, '')));
          let genderLabel: any = [...genderArrayLabelSet];
          genderLabel.sort();
          setGenderArrayLabel(genderLabel);
          const newColumnChartData = [
            {
              name: "Male",
              data: mGenderArray,
              color:'#3592FC'
            },
            {
              name: "Female",
              data: fGenderArray,
              color: '#FF78B1'

            },
          ];

          setColumnChartData(newColumnChartData);

        } else {
          // localService.toastify(response.data.status.message, "info", 1000);
        }


        //Gender Split Data
        const genderSplitData = response?.data?.rows?.detail;
        if (Array.isArray(genderSplitData)) {

          let maleArray = genderSplitData[0]?.total_male_count
          let femaleArray = genderSplitData[0]?.total_female_count

          // setMaleSplit(maleArray);
          // setFemaleSplit(femaleArray);

          const genderPieChartData = [
            { name: "Male", y: maleArray, color: "#3592FC" },
            { name: "Female", y: femaleArray, color: "#FF78B1" },
          ];

          setGenderSplitPieChartData(genderPieChartData);
        } else {
          // localService.toastify(response.data.status.message, "info", 1000);
        }

        //Post Est Reach Comparison Data

        const erComparisonData = response?.data?.rows?.detail;
        if (Array.isArray(erComparisonData)) {
          let imageArray = ((erComparisonData[0]?.image_posts_est_reach_comparison + erComparisonData[0]?.carousel_posts_est_reach_comparison) / 2) || 0;
          let reelArray = erComparisonData[0].reels_posts_est_reach_comparison || 0;
          // let carouselArray = erComparisonData[0]?.carousel_posts_est_reach_comparison ? erComparisonData[0]?.carousel_posts_est_reach_comparison : 0;
          const postErData = [
           
            {
              name: "Images",
              type: "column",
              groupPadding: 0.8,
              data: [{
                name: 'image',
                value: imageArray
              }],
              color: "#0A7447",
            },
            {
              name: "Reels",
              type: "column",
              groupPadding: 0.8,
              data: [{
                name: 'reel',
                value: reelArray
              }],
              color: "#34E098",
            },
            // {
            //   name: "Carousel",
            //   type: "column",
            //   groupPadding: 0.8,
            //   data: [{
            //     name: 'carousel',
            //     value: carouselArray
            //   }],
            //   color: "#5da8b0",
            // },
          ];

          setPostErComparisonData(postErData);
        } else {
          // localService.toastify(response.data.status.message, "info", 1000);
        }
      }
    } catch (error) {
      console.log(`inflProfileService error :: ${JSON.stringify(error)}`);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    _InfluencerList();

  }, [props.UserReducer.brand_id, props.UserReducer.user_id]);

  const handleAddToListClose = () => {
    setShowAddToListPopup(!showAddToListPopup);

  };

  const handleAddToListShow = () => {
    setShowAddToListPopup(!showAddToListPopup); // Toggle showPop
  }

  const handleAddToCampaignShow = () => {
    setShowPopAddToCampaign(!showPopAddToCampaign); // Toggle showPop
  }

  useEffect(() => {
    const bodyElement = document.getElementsByTagName('body')[0];
    if (bodyElement) {
      if (showAddToListPopup || showPopAddToCampaign) {
        bodyElement.classList.add("overflow-hidden", "overlay");
      } else {
        bodyElement.classList.remove("overflow-hidden", "overlay");
      }
    } else {
      console.error("Body element not found!");
    }
  }, [showAddToListPopup, showPopAddToCampaign]);



  function _InfluencerList() {
    CampaignService.influencerList({
      user_id: props.UserReducer.user_id,
      brand_id: props.UserReducer.brand_id,
    }).then((res: any) => {
      if (res.data.status === 1) {
        setInfluencerList(res.data.rows);
      } else {
        setInfluencerList(null);
      }
    });
  }

  const handleCloseAddToCamp = () => {
    setShowPopAddToCampaign(false);
  }

  //Male and female and Adult count
  const totalMaleCount = influencerData?.detail?.[0]?.total_male_count ?? 0;
  const totalFemaleCount = influencerData?.detail?.[0]?.total_female_count ?? 0;


  const totalPopulation = totalMaleCount + totalFemaleCount;

  const handleOpenUpgradeBrandModal = (value:boolean) => {
    setOpenUpgradeBrandModal(value)
  };
  function handleCloseUpradeBrandModal() {
    setOpenUpgradeBrandModal(false);
  }
  return (
    <Fragment>
      {/* {loading && <div className='container'>Loading</div>} */}
      <div className="container">
        <div className="influencer-profile">
          {/* <div className="common-tab mb-0 d-lg-none common-tab-mobile">
            <span className="active common-tab-menu">Instagram</span>
            <span className="common-tab-menu d-none">YouTube</span>
          </div> */}
          <div className="influencer-profile__left">
            <InfluencerProfileLeft influencerData={influencerData} email={email} phone={phone} location={location} category={categories} loading={loading} />         
          </div>
          <div className="influencer-profile__right">
            <div className="d-flex flex-wrap w-100 align-items-center mb-3">
              {/* <div className="common-tab mb-0 d-none d-lg-inline-flex">
                <span className="active common-tab-menu">{influencerData?.profile_detail?.[0]?.username && <InstagramIcon1 width="20px" height="20px" />}{influencerData?.profile_detail?.[0]?.username}</span>
                {/* <span className="common-tab-menu">YouTube</span>
              </div> */}
              <div className="d-flex align-items-center">
              {influencerData?.profile_detail?.[0]?.username && <InstagramIcon1 width="20px" height="20px" className="mb-1" />}
              <Link target="_blank" className="btn btn-text-link" to={`https://www.instagram.com/${influencerData?.profile_detail?.[0]?.username}`}>
              <span>{influencerData?.profile_detail?.[0]?.username}</span>
              </Link>
              </div>
              {(props.UserReducer.account_type === 'Brand' || props.UserReducer.account_type === 'Agency') && (
                <div className='infl-profile-detail-action ms-auto'>
                  <button
                    disabled={props.getSubscriptionDetail && props.getSubscriptionDetail.isBasicUser ? true : false}
                    className="btn btn-text-link me-2"
                    onClick={handleAddToCampaignShow}
                  >
                    Add to campaign
                  </button>
                  <button
                    className="btn btn-text-link me-2"

                    onClick={handleAddToListShow}

                  >
                    Add to list
                  </button>
                </div>
              )}
            </div>
            {/* {loading ? <Skeleton height={24} /> :
              <div className="d-none d-lg-flex gap-1"><InstagramIcon1 width="20px" height="20px" /><Link
                className="influencer-profile__social-chan d-none d-lg-flex"
                to={`https://www.instagram.com/${influencerData && influencerData?.profile_detail?.[0]?.username}`}
                target="_blank"
              >
                <span className="influ-prof-info-social-username d-flex justify-content-center align-items-baseline">
                  {loading ? <Skeleton height={30} /> : <>@{influencerData?.profile_detail?.[0]?.username}</>}
                </span>
              </Link>
              </div>
            } */}
            <div className="followers-info">
              {/* FOLLOWERS SECTION */}
            <FollowerSection influencerData={influencerData} />
            {/* FOLLOWERS SECTION ENDS */}
            {/* FOLLOWERS SECTION INLINE */}
            <FollowerSecInline influencerData={influencerData} />
            {/* FOLLOWERS SECTION INLINE ENDS */}
            {/* POST FREQUENCY SECTION  */}
            <PostFrequency influencerData={influencerData} />
            </div>
            {/* POST FREQUENCY SECTION ENDS  */}
            {/* Audience */}
            {(influencerData?.age_gender?.length > 0 || genderSplitPieChartData[0]?.y > 0 || genderSplitPieChartData[1]?.y > 0 || influencerData?.city_country?.length > 0 || influencerData?.city_indian?.length > 0 || influencerData?.city_international?.length > 0) &&
              <Audience genderSplitPieChartData={genderSplitPieChartData} influencerData={influencerData} columnChartData={columnChartData} genderArrayLabel={genderArrayLabel} loading={loading} handleChange={handleChange} value={value} />
            }

            {/* ENGAGEMENT */}
            {(influencerData?.profile_data?.[0]?.reels_total_reach || influencerData?.profile_data?.[0]?.image_total_reach || influencerData?.profile_data?.[0]?.carousel_avg_impressions || postErComparisonData[0]?.data[0]?.value > 0 || postErComparisonData[1]?.data[0]?.value > 0) &&
              <Engagement influencerData={influencerData} loading={loading} postErComparisonData={postErComparisonData} />
            }
            {(influencerData?.top_stories?.length !== 0 || influencerData?.most_viewed_reels?.length !== 0 || influencerData?.most_liked_images?.length !== 0) &&
              <strong className="influencer-profile__common__title pb-1">Posts</strong>
            }
            {/* MOST VIEWED STORIES STARTS*/}
            {(influencerData?.top_stories?.length > 0) &&
              <section className='sec-media-posts'>
                <div className='container custom-container'>
                  <strong className='sec-media-posts__title'>Stories</strong>
                  <InsightMediaDetailCard influencerData={influencerData && influencerData} loading={loading} />
                </div>
              </section>
            }
            {/* MOST VIEWED STORIES ENDS */}
            {/* MOST VIEWED REELS STARTS */}
            {influencerData?.most_viewed_reels?.length > 0 &&
              <section className='sec-media-posts'>
                <div className='container custom-container'>
                  <strong className='sec-media-posts__title'>Most Viewed Reels</strong>
                  <InsightMediaReelsCard influencerData={influencerData} />
                </div>
              </section>
            }
            {/* MOST VIEWED REELS ENDS */}
            {/* MOST LIKED IMAGE POSTS STARTS */}
            {influencerData?.most_liked_images?.length > 0 &&
              <section className='sec-media-posts'>
                <div className='container custom-container'>
                    <strong className='sec-media-posts__title'>Most Viewed Images</strong>
                  

                  <InsightMediaImageCard influencerData={influencerData && influencerData} />
                </div>
              </section>
            }

            {/* MOST LIKED IMAGE POSTS ENDS */}
          </div>
        </div>
      </div>
      {showPopAddToCampaign && (
        <AddToCampaign
          platform="instagram"
          UserReducer={props.UserReducer}
          selectedInfluencer={selectInflu}
          showPopAddToCampaign={showPopAddToCampaign}
          handleCloseAddToCamp={handleCloseAddToCamp}
        />
      )}
      {
        showAddToListPopup && (
          <div className='popper-popup'>
            {showAddToListPopup && (
              <AddToList
                platform="instagram"
                UserReducer={props.UserReducer}
                selectedInfluencer={selectInflu}
                showAddToListPopup={showAddToListPopup}
                handleAddToListClose={handleAddToListClose}
                handleOpenUpgradeBrandModal={handleOpenUpgradeBrandModal}

              />
            )}
      {openUpgradeBrandModal && (
        <UpgradeBrandPopUp
          openUpgradeBrandModal={openUpgradeBrandModal}
          onClose={() => handleCloseUpradeBrandModal()}
          popupMinWidth='630px'
        >
        </UpgradeBrandPopUp>
      )}

          </div>
        )
      }
    </Fragment >
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    getSubscriptionDetail: state.Subscription.subscription_detail
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(ProfileInsight);

export { connectToRedux as ProfileInsight };