import { Link } from 'react-router-dom';
import HeadingStyle from '../../../../components/heading-style/heading-style';
import InstagramIcon from '../../../../components/icons/instagram-icon';
import * as Utility from '../../../../core/utility';

import React from 'react'
import { Checkbox, FormControlLabel, InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { CurrencyRupee, YouTube } from '@mui/icons-material';
import SwitchButton from '../../../../components/switch-button/switch-button';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import { convert } from 'html-to-text'
import MaterialSymbol from '../../../../components/icons/material-symbol';

const diffMethods: Utility.Methods = new Utility.Methods();

type Props = {
  checkTermsCheckbox: boolean;
  campaignDetail: any;
  myref?: any;
  handleSubmit: () => void;
  deliverables: any;
  handleApply: any;
  campaign_cost_model_value: any;
  campaign_cost_model?: any;

  handleCost: any;
  costPerUnitError: { status: boolean, message: string };
  handleSwitch: any;
  totalAmount: number;
  userReducer: any;
  proposalChats: any;
  comment: string;
  setComment: any;
  errorTerms: boolean;
  setCheckTermsCheckBox: any;
  handleCancel: () => void;
  loading: boolean;
}
interface InputProps {
  handleCost: (
    index: number,
    qty: number,
    value: string,
    skipqty: boolean,
  ) => void;
  index: number;
  qty: number;
  skipAdd: boolean;
  placeholder?: string;
  type?:number;
}

const InputField: React.FC<InputProps> = (props: InputProps) => {
  const handleNumericChange = (e:any) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) { 
      if (props.handleCost) {
        props.handleCost(props.index, props.qty, value, props.skipAdd);
      }
    }
  };
  return (
    <TextField
      size="small"
      type="number"
      style={{ width: `100%` }}
      id={`id_${props.index}_input_field`}
      defaultValue=""
      placeholder={props.placeholder}
      onChange={handleNumericChange}
      InputProps={{
        startAdornment: <InputAdornment position="start"><CurrencyRupee className='fs-18' /></InputAdornment>,
      }}
      InputLabelProps={{ shrink: false }}
      label=" "
      variant="outlined"
    />
  );
};


const SubmitAProposal = (props: Props) => {
  
  let campaignDescription = convert(props?.campaignDetail?.campaign_description) || ''; 
  let limitedDescription = campaignDescription.slice(0, 150);
  if (campaignDescription.length > 150) {
    limitedDescription += '...';
  }

  // console.log("props?.campaignDetail", props?.campaignDetail)
  return (
    <div className="submit-proposal-initial" >
      <div className='submit-proposal-card wo-bdr'>
        <div className="row">
          <div className="col">
            <HeadingStyle className='' title="Submit a proposal" />
          </div>
        </div>
      </div>
      {/* PROPOSAL DETAIL INFO */}
      <div className='submit-proposal-card submit-proposal-initial-detail'>
        <div className='submit-proposal-initial-detail__left'>
          <h3>Campaign Details</h3>
          <div className='submit-proposal-initial-detail__left__top'>
            <div className='submit-proposal-initial-detail__left__top_left'>
              <span className="d-none d-md-block color-blk-5d fs-14 mb-1">Posted  {diffMethods.calculateDaysDifference(new Date(props?.campaignDetail?.posted_at), new Date())} days ago</span>
              <span className='submit-proposal-camp-title'>{props?.campaignDetail?.campaign_title}</span>
              
              
            </div>
           
            <span className='badge align-self-start'>{(
              props?.campaignDetail?.campaign_platform + ''
            ).toLocaleLowerCase() === 'instagram' ? (
              <InstagramIcon />
            ) : (
                <YouTube style={{marginRight: '5px'}} />
            )}{props?.campaignDetail?.campaign_type}
            </span>
          </div>
          {props?.campaignDetail?.campaign_description &&
            <div className='camp-description'>
              {/* {convert(props?.campaignDetail?.campaign_description)} */}
              {limitedDescription}
            </div>
          }

          <Link to={`/influencer/campaign/campaigndetails?campaignId=${props?.campaignDetail?.campaign_id}&smId=${props?.campaignDetail?.smId}`} className="btn btn-text-link">View the campaign brief</Link>

        </div>
        <div className='submit-proposal-initial-detail__right'>
          {/* CAMPAIGN DURATION INFO */}
          <div className='dashboard-card-camp-dur'>
            <div className='infl-det-list-sec proposal-dur-info'>
              <div className='infl-det-list'>
                <div className='infl-det-list-icon'>
                  <MaterialSymbol iconName='alarm' fontweight='200' />
                </div>
                <div className='infl-det-list-content'>
                  <strong>{dayjs(props?.campaignDetail?.proposal_dt).format('LL')} </strong>
                  <ul>
                    <li>Proposal Deadline</li>
                  </ul>
                </div>

              </div>
              <div className='infl-det-list'>
                <div className='infl-det-list-icon'>
                  <MaterialSymbol iconName='calendar_month' fontweight='200' />
                </div>
                <div className='infl-det-list-content'>
                  <strong>{dayjs(props?.campaignDetail?.go_live_dt).format('LL')} </strong>
                  <ul>
                    <li>Campaign Live Date</li>
                  </ul>
                </div>

              </div>

            </div>
          </div>
          {/* END CAMPAIGN DURATION INFO */}
        </div>
      </div>
      {/* submit-proposal-form  */}
      <div className='submit-proposal-card'>
        {props.campaignDetail.campaign_type !== "Barter" && (
          <h3 className='mb-2'>Collaboration Costs</h3>
          )}
        {props.campaignDetail.campaign_type === "Barter" && (
            <h3 className='mb-2'>Collaborations</h3>
          )}
        
        <TableContainer
          className="common-table table-responsive bdr-rad-0"
        >
          <Table
            stickyHeader
            aria-label="simple table"
          >
            <TableHead className='common-table-header'>
              <TableRow>
                <TableCell align="left">
                  Deliverables
                </TableCell>
                <TableCell align="left">Qty</TableCell>
                {props.campaign_cost_model_value && (
                  <>
                    {(props.campaignDetail.campaign_type + "")
                      .toLocaleLowerCase()
                      .includes("paid") && (
                        <>
                          <TableCell align="left" style={{minWidth:'150px'}}>Cost per Unit</TableCell>
                        {/* <TableCell align="left" style={{minWidth:"180px"}}>Total Cost</TableCell> */}
                    <TableCell align="left" style={{minWidth:"100px", paddingLeft:'0', paddingRight:'0'}}>Is this cost negotiable?</TableCell>
                        </>
                      )}
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody className="common-table-body">
              {(props.deliverables?.length > 0) && props.deliverables &&
                props.deliverables?.map((res: any, index: number) => {
                  if (res?.deliverable_item === "ig_reel") {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell>Reel</TableCell>
                        <TableCell>{res.qty}</TableCell>
                        {props.campaign_cost_model_value && (
                          <>
                            {(props.campaignDetail.campaign_type + "")
                              .toLocaleLowerCase()
                              .includes("paid") && (
                                <>
                                  <TableCell>
                                    <InputField
                                      index={index}
                                      qty={res.qty}
                                      skipAdd={false}
                                      handleCost={props.handleCost}
                                    />{props.costPerUnitError && <span className="error pt-1">{props.costPerUnitError.message}</span>}
                                  </TableCell>
                                  {/* <TableCell><TextField
                                    size='small'
                                    disabled
                                    label=""
                                    defaultValue={res.price}
                                    value={res.price}
                                    InputProps={{
                                      startAdornment: <InputAdornment position="start"><CurrencyRupee className='fs-18' /></InputAdornment>,
                                    }}
                                  />
                                  </TableCell> */}
                                  <TableCell className='switch-cell'>
                                    <SwitchButton
                                      handleSwitch={props.handleSwitch}
                                      index={index}
                                    />
                                  </TableCell>
                                </>
                              )}
                          </>
                        )}
                      </TableRow>
                    );
                  } else if (res.deliverable_item === "ig_story") {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell>Story</TableCell>
                        <TableCell>{res.qty}</TableCell>
                        {props.campaign_cost_model_value &&
                          (props.campaignDetail.campaign_type + "")
                            .toLocaleLowerCase()
                            .includes("paid") && (
                            <>
                              <TableCell>
                                <InputField
                                  index={index}
                                  qty={res.qty}
                                  skipAdd={false}
                                  handleCost={props.handleCost}

                                />{props.costPerUnitError && <span className="error pt-1">{props.costPerUnitError.message}</span>}
                              </TableCell>
                              {/* <TableCell>
                                <TextField
                                  size='small'
                                  disabled
                                  id="outlined-disabled"
                                  label=""
                                  defaultValue={res.price}
                                  value={res.price}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start"><CurrencyRupee className='fs-18' /></InputAdornment>,
                                  }}
                                /></TableCell> */}
                              <TableCell className='switch-cell'>
                                <SwitchButton
                                  handleSwitch={props.handleSwitch}
                                  index={index}
                                />
                              </TableCell>
                            </>
                          )}
                      </TableRow>
                    );
                  } else if (
                    res.deliverable_item === "ig_static"
                  ) {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell>
                          Static <br className='d-md-none' />  Images - {
                            props.deliverables.filter((item: any) => item.deliverable_item === "ig_static_image")[0]?.qty
                          }
                        </TableCell>
                        <TableCell>{res.qty}</TableCell>
                        {props.campaign_cost_model_value &&
                          (props.campaignDetail.campaign_type + "")
                            .toLocaleLowerCase()
                            .includes("paid") && (
                            <>
                              <TableCell>
                                <InputField
                                  index={index}
                                  qty={res.qty}
                                  skipAdd={false}
                                  handleCost={props.handleCost}
                                />{props.costPerUnitError && <span className="error pt-1">{props.costPerUnitError.message}</span>}
                              </TableCell>
                              {/* <TableCell> <TextField
                                size='small'
                                disabled
                                id="outlined-disabled"
                                label=""
                                defaultValue={res.price}
                                value={res.price}
                                InputProps={{
                                  startAdornment: <InputAdornment position="start"><CurrencyRupee className='fs-18' /></InputAdornment>,
                                }}
                              /></TableCell> */}
                              <TableCell className='switch-cell'>
                                <SwitchButton
                                  handleSwitch={props.handleSwitch}
                                  index={index}
                                />
                              </TableCell>
                            </>
                          )}
                      </TableRow>
                    );
                  } else if (res.deliverable_item === "yt_shorts") {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell>Shorts</TableCell>
                        <TableCell>{res.qty}</TableCell>
                        {props.campaign_cost_model_value &&
                          (props.campaignDetail.campaign_type + "")
                            .toLocaleLowerCase()
                            .includes("paid") && (
                            <>
                              <TableCell>
                                <InputField
                                  index={index}
                                  qty={res.qty}
                                  skipAdd={false}
                                  handleCost={props.handleCost}
                                />{props.costPerUnitError && <span className="error pt-1">{props.costPerUnitError.message}</span>}
                              </TableCell>
                              {/* <TableCell>
                                
                                <CurrencyRupee fontSize="small" />{" "}
                                    {res.price}
                                <TextField
                                  size='small'
                                  disabled
                                  id="outlined-disabled"
                                  label=""
                                  defaultValue={res.price}
                                  value={res.price}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start"><CurrencyRupee className='fs-18' /></InputAdornment>,
                                  }}
                                />

                              </TableCell> */}
                              <TableCell className='switch-cell' width={100}>
                                <SwitchButton
                                  handleSwitch={props.handleSwitch}
                                  index={index}
                                />
                              </TableCell>
                            </>
                          )}
                      </TableRow>
                    );
                  } else if (
                    res.deliverable_item === "yt_dedicated_video"
                  ) {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell>Dedicated Video</TableCell>
                        <TableCell>{res.qty}</TableCell>
                        {props.campaign_cost_model_value &&
                          (props.campaignDetail.campaign_type + "")
                            .toLocaleLowerCase()
                            .includes("paid") && (
                            <>
                              <TableCell>
                                <InputField
                                  index={index}
                                  qty={res.qty}
                                  skipAdd={false}
                                  handleCost={props.handleCost}
                                />{props.costPerUnitError && <span className="error pt-1">{props.costPerUnitError.message}</span>}
                              </TableCell>
                              {/* <TableCell>
                                <TextField
                                  size='small'
                                  disabled
                                  id="outlined-disabled"
                                  label=""
                                  defaultValue={res.price}
                                  value={res.price}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start"><CurrencyRupee className='fs-18' /></InputAdornment>,
                                  }}
                                />
                              </TableCell> */}
                              <TableCell className='switch-cell'>
                                <SwitchButton
                                  handleSwitch={props.handleSwitch}
                                  index={index}
                                />
                              </TableCell>
                            </>
                          )}
                      </TableRow>
                    );
                  } else if (
                    res.deliverable_item === "yt_integrated_video"
                  ) {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell>Integrated Video</TableCell>
                        <TableCell>{res.qty}</TableCell>
                        {props.campaign_cost_model_value &&
                          (props.campaignDetail.campaign_type + "")
                            .toLocaleLowerCase()
                            .includes("paid") && (
                            <>
                              <TableCell>
                                <InputField
                                  index={index}
                                  qty={res.qty}
                                  skipAdd={false}
                                  handleCost={props.handleCost}
                                />{props.costPerUnitError && <span className="error pt-1">{props.costPerUnitError.message}</span>}
                              </TableCell>
                              {/* <TableCell>
                                <TextField
                                  size='small'
                                  disabled
                                  id="outlined-disabled"
                                  label=""
                                  defaultValue={res.price}
                                  value={res.price}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start"><CurrencyRupee className='fs-18' /></InputAdornment>,
                                  }}
                                />
                              </TableCell> */}
                              <TableCell className='switch-cell'>
                                <SwitchButton
                                  handleSwitch={props.handleSwitch}
                                  index={index}
                                />
                              </TableCell>
                            </>
                          )}
                      </TableRow>
                    );
                  } else if (res.deliverable_item === "digital_rights") {
                    return (
                      <>
                        <TableRow className="common-table-blank-row">
                          <TableCell colSpan={5} className='p-0'><hr /></TableCell>
                        </TableRow>
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell className='pb-0' colSpan={4}>Cost for {res.qty} days Digital Rights</TableCell>
                        </TableRow>
                        <TableRow
                          key={index}
                          className='pt-0'
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >

                          {props.campaign_cost_model_value &&
                            (props.campaignDetail.campaign_type + "")
                              .toLocaleLowerCase()
                              .includes("paid") && (
                              <>
                                <TableCell colSpan={3}>
                                  <InputField
                                    index={index}
                                    qty={res.qty}
                                    skipAdd={true}
                                    handleCost={props.handleCost}
                                  />{props.costPerUnitError && <span className="error pt-1">{props.costPerUnitError.message}</span>}
                                </TableCell>
                                {/* <TableCell> {res.price}</TableCell> */}
                                <TableCell className='switch-cell'>
                                  <SwitchButton
                                    handleSwitch={props.handleSwitch}
                                    index={index}
                                  />
                                </TableCell>
                              </>
                            )}
                        </TableRow>
                      </>
                    );
                  } else if (
                    res.deliverable_item === "available_for_exclusivity"
                  ) {
                    return (
                      <>
                        <TableRow>
                          <TableCell className='pb-0' colSpan={4}>Cost for {res.qty} Days Exclusivity</TableCell>
                        </TableRow>
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          {props.campaign_cost_model_value &&
                            (props.campaignDetail.campaign_type + "")
                              .toLocaleLowerCase()
                              .includes("paid") && (
                              <>
                                <TableCell colSpan={3}>
                                  <InputField
                                    index={index}
                                    qty={res.qty}
                                    skipAdd={true}
                                    handleCost={props.handleCost}
                                  />{props.costPerUnitError && <span className="error pt-1">{props.costPerUnitError.message}</span>}
                                </TableCell>
                                {/* <TableCell> {res.price}</TableCell> */}
                                <TableCell className='switch-cell'>
                                  <SwitchButton
                                    handleSwitch={props.handleSwitch}
                                    index={index}
                                  />
                                </TableCell>
                              </>
                            )}
                        </TableRow>
                        {props.campaignDetail.campaign_type === "Barter" && 
                          <TableRow>
                            <TableCell className='pb-0' colSpan={4}>&nbsp;</TableCell>
                          </TableRow>
                        }
                        {/* <TableRow className="common-table-blank-row">
                          <TableCell colSpan={5} className='p-0'><hr /></TableCell>
                        </TableRow> */}
                      </>
                    );
                  }

                  return null;
                })}
              {/* PACKAGE COST */}
              {!props.campaign_cost_model_value && (
                <>
                  {props.deliverables?.length > 0 && props.deliverables &&
                    props.deliverables?.map((res: any, index: number) => {
                      if (
                        (res.deliverable_item + "").toLocaleLowerCase() ===
                        "package cost 1"
                      ) {
                        return (
                          <>
                          <TableRow className='row-package-cost'
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell>{res.deliverable_item} </TableCell>

                            
                            {/* <> */}
                            <TableCell colSpan={2}>
                              <InputField
                                index={index}
                                qty={res.qty}
                                skipAdd={true}
                                handleCost={props.handleCost}
                                placeholder="0"
                              />{props.costPerUnitError && <span className="error pt-1">{props.costPerUnitError.message}</span>}
                            </TableCell>
                            
                            
                            {/* </> */}

                          </TableRow>
                          <TableRow>
                          <TableCell>Is this cost negotiable? </TableCell>
                            <TableCell className='switch-cell'>
                              <SwitchButton
                                handleSwitch={props.handleSwitch}
                                index={index}
                              />
                            </TableCell>
                          </TableRow>
                          </>

                        );
                      }

                      return null; // JSON.stringify(deliverables);
                    })}
                </>

              )}

              {
                props.campaignDetail.campaign_type !== "Barter" && (
                  <>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell className='py-3' colSpan={!props.campaign_cost_model_value ? 1 : 1}><strong>Total Amount:</strong>{" "}</TableCell>
                      <TableCell className='py-3' colSpan={!props.campaign_cost_model_value ? 1 : 2}>
                        <TextField
                          size='small'
                          className='w-100'
                          disabled
                          label=""
                          defaultValue={props.totalAmount}
                          value={props.totalAmount}
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><CurrencyRupee className='fs-18' /></InputAdornment>,
                          }}
                        />
                      </TableCell>
                      {props?.campaign_cost_model?.includes("Package") && <TableCell>&nbsp;</TableCell>}
                    </TableRow>
                  </>
                )
              }

            </TableBody>


          </Table>
        </TableContainer>
      </div>
      <div className='submit-proposal-card'>
        {/* {proposalStatus > 1 && ( */}
        {/* <strong className="d-block">Comments</strong> */}
        {/* <ul>
          {props.proposalChats &&
            props.proposalChats?.map((data: any, index: number) => {
              return (
                <li key={index}>
                  <span>{dayjs(data.created_at).format("LL")}</span>
                  <span>{data.account_id === props.userReducer.account_id ? 'You' : props.userReducer.account_name}</span>
                  <span>{data.comments}</span>
                </li>
              );
            })}
        </ul> */}
        <TextField
          className="w-100 mb-4"
          id="outlined-multiline-static"
          label="Comments"
          placeholder='You may add about your profile or campaign links'
          multiline
          rows={2}
          value={props.comment}
          onChange={(e) => props.setComment(e.target.value)}
        />
        <div className="row">
          <div className="col terms_conditions">
            <span>
              <FormControlLabel
                className='custom-checkbox m-0'
                control={
                  <Checkbox
                    className='p-0'
                    onChange={(e, v) => props.setCheckTermsCheckBox(v)}
                  />
                }
                label={<Typography className='d-flex align-items-end'>
                  I agree to the <Link to="" className='btn btn-text-link ms-1'>Terms & Conditions</Link>
                </Typography>}
              />
            </span>
            {props.errorTerms && (
              <span className="error pt-1">Terms & Conditions are required</span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col text-end apply-btn d-flex justify-content-end gap-2">
            <LoadingButton

              className="btn btn-outline-primary btn-rounded btn-lg"
              loading={props.loading}
              loadingPosition="start"
              variant="contained"
              onClick={props.handleCancel}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              className="btn btn-primary btn-rounded btn-lg"
              loading={props.loading}
              loadingPosition="start"
              variant="contained"
              // onClick={handleClickApplyOpen}
              onClick={() => {
                props.handleApply();
              }}
              disabled={!props.checkTermsCheckbox}
            >
              Apply
            </LoadingButton>
          </div>
        </div>


      </div>

    </div>
  )
}

export default SubmitAProposal
